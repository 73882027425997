.menucard { 
    background-color: white;
    padding: 30px;    
}

.menucard h1 {
    font-family: "Great Vibes", Arial, sans-serif;
    font-size: 400%;    
    color: #82ae46;
    padding-top: 20px;
}

.menucard h4 {
    padding-top: 15px;
    color: red;
}

.menucard a {
    color: red;
    text-decoration: underline;
}

.menucard h1, .menucard h2, .menucard h3, .menucard h4, .menucard h5 { 
    text-align: center;
}

.menucard-item {
    border-bottom: 1px dotted lightgrey;
    padding-bottom: 25px;    
}


.mealday {
    padding-top: 15px;
    font-size: 20px; 
    text-decoration: underline;
}

.meal {
    padding-left: 20px;
    padding-top: 5px;
    font-size: 18px;  
    float:clear;   
    font-weight: bold;
}
.meal-addition {
    padding-left: 20px;
    padding-top: 5px;
    font-size: 18px;             
}

.price {
    float:right;    
    padding-right: 20px;
}