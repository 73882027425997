.servicesection {
    background-color: #82ae46;
    color: white;
    padding: 30px;
}

.servicesection li {
    padding: 0px 10px;
}

.servicesection a {
    text-decoration: none;
    color: white;   
}

.servicesection h1 {
    text-align: center;
}

.servicesection table {
    color: #343a40
}

.servicesection h2 {
    font-size: 1.5em;
}

.weekendOpen {
    text-align: center;
}

.map {
    height: 400px;
    width: 100%; 
}