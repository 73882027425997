.stationOfYear {
    padding-top: 20px;
    background-color: #f5f5dc;        
    text-align:center;
 }
.stationOfYear p {    
    color: rgb(49, 71, 52);
    text-align:left;  
    padding: 30px;
}

.stationOfYear h1 {
    font-family: 'Bahnschrift';
    font-size: 4em;
    color: firebrick;
    text-align: center;
    margin-bottom: 30px;    
}

iframe {
    margin: 0 auto;
    background-color: #777;
}