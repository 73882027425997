.jumbotron {
    margin-bottom: 0px !important;
    background-color: gray; 
    color: white;
    text-align: center;
    padding: 0px;
    
}

.jumbotron h1 {
    font-size: 70px;
}

.jumbotron h2 {    
    font-family: serif;
}