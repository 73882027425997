.offersection {
    background-color: #FFEA9E;   
    color: firebrick;
    padding: 15px;    
}

.offersection p {
    text-align: center;    
    color: rgb(49, 71, 52);
    font-size: 1.5em;
    font-weight: bold;
}

.offersection h1 {
    text-align: center;
    margin-bottom: 20px;
}
.offersection ul {
    padding-left: 40px;
    list-style: none;
}
.offersection li {
    list-style-position: outside;
    text-indent: -1.5em;
}

.offersection ul li::before {
    content: "\25B6";      
    padding-right: 10px;
}

.offersection ol {
    padding-left: 40px;
    list-style: none;
}

.offersection ol li::before {
    content: "\25B7";      
    padding-right: 10px;
}

