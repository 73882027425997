.imprint {
    background-color: gray;
    color: white;    
    padding: 30px;
    padding-top: 50px;
    display: block;
}

.imprint hr {
    background-color: rgb(141, 141, 141);
    height: 1px;
    width: 80%;
    margin: auto;
}
.imprint a {    
    color: white;
    text-decoration: none;
}

.copyright {
    margin-top: 20px;
    text-align: center;
}